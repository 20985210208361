import {graphql} from 'gatsby';
import {css, cx} from 'linaria';
import * as React from 'react';
import Page, {PageProps} from '../components/Page';
import Sidebar, {SidebarData} from '../components/Sidebar';

export default ({data: sidebarData, location, ...props}: {data: AboutData} & PageProps) => (
  <Page title="Not Found – Just Pick This" location={location} {...props}>
    <div className="centered">
      <div
        className={cx(
          'centered',
          css`
            max-width: 460px;
            text-align: left;
            @media (min-width: 480px) {
              padding-left: 5vh;
            }
          `,
        )}>
        <h2 className="sc l b mbm mtl">Not Found</h2>
        <p className="mbl">There’s nothing here. Please use the links on the left to browse products.</p>
      </div>
    </div>
    <Sidebar data={sidebarData} pathname={location.pathname} />
  </Page>
);

type AboutData = SidebarData;

export const query = graphql`
  {
    ...SidebarFragment
  }
`;
